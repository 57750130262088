.cloud-first-section-main {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding-top: 15%;
}

.cloud-first-section-main:before {
    content: "";
    position: absolute;
    top: -330px;
    left: -230px;
    width: 600px;
    height: 600px;
    background-color: #e31e25;
    border-radius: 50%;
    z-index: 2;

}

.first-section-left {
    width: 50%;
}

.first-section-left h1 {
    color: #000;
    font-family: 'Inter';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.first-section-left p {
    color: #000;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;
}

.first-section-left span {
    font-weight: 600;
}


.first-section-right {
    width: 40%;
}

.infinite {
    width: 571.211px;
    height: 437.129px;
    transform: rotate(8.984deg);
}

.cloud-button {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 23px;
    background: #1B1B1B;
    border: none;

    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 38px;
}

.infinitemob {
    display: none;
}

/*SECTION 2*/


.cloud-second-section-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(234, 0, 41, 0.05);
    padding-bottom: 5%;
}

.second-cards-mob {
    display: none;
}

.second-context {
    padding: 5% 13%;
    width: 90%;
}

.second-cards-web {
    display: flex;
    gap: 24px;
    padding-bottom: 28px;
}

.second-context h1 {
    color: #E31E25;
    text-align: center;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.64px;
}

.second-context p {
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-top: 12px;

}

.card-main {
    width: 100%;
    max-width: 357px;
    padding: 24px;


    background: #FFF;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;
    gap: 16px;

    box-shadow: 0px 3px 7px 0px rgba(255, 47, 57, 0.10), 0px 12px 12px 0px rgba(255, 47, 57, 0.09), 0px 28px 17px 0px rgba(255, 47, 57, 0.05), 0px 50px 20px 0px rgba(255, 47, 57, 0.01), 0px 78px 22px 0px rgba(255, 47, 57, 0.00);

}

.card-main h2 {
    color: #242424;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 91.023%;
    /* 14.564px */
}

.card-main p {
    color: #444;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}



/* THIRD SECTION*/

.point {
    background: #CB2E3A;
    padding: 22px;
    border-radius: 5px 5px 5px 0px;
    border-bottom: none;
    max-height: 68px;
    overflow: hidden;
    transition: max-height 0.5s ease;
    margin-bottom: 15px;
}

.point-heading {
    display: flex;
    align-items: flex-start;
    width: fit-content;
    gap: 11px;
}

.point-heading h1 {
    color: var(--white, #FFF);
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.846px;
}


.point p {
    color: var(--white, #FFF);
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.277px;
    width: fit-content;
    padding-left: 35px;
    padding-top: 13px;
}

.point.selected {
    border-bottom: 1px solid #CB2E3A;
}

.point:not(.selected) {
    border-bottom: none;
}

.features-point-right img {
    border-radius: 12px;
    transition: opacity 0.3s ease;
}


.cloud-third-section-main {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 5%;
    align-items: center;
    justify-content: center;
    background: #ECECEC;
}

.third-context {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 5% 2%;
    width: 67%;
    align-items: center;
    justify-content: center;
}

.third-context h1 {
    color: #CB2E3A;
    text-align: center;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.third-context p {
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
}

.third-points {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.third-points-left {
    width: 40%;
}

.third-points-right {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}



/*FOURTH SECTION*/

.cloud-fourth-section-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(234, 0, 41, 0), rgba(234, 0, 41, 0.05));
    padding-bottom: 5%;
}

.fourth-section-context {
    display: flex;
    flex-direction: column;
    padding: 5% 0%;
}

.fourth-section-context h1 {
    color: #E31E25;
    text-align: center;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.fourth-section-context p {
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
}

.fourth-section-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
}

.fourth-section-mob {
    display: none;
}

.grid-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 42px 0px 33px 0px;
    max-width: 290px;

}

.grid-card h1 {
    color: #333;
    text-align: center;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    padding-top: 16px;
    padding-bottom: 6px;
}

.grid-card p {
    color: #444;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}



/*FIFTH SECTION*/
.cloud-fifth-section-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
    background: linear-gradient(180deg, rgba(218, 218, 218, 0.00) 0%, #F4F4F4 100%);

}

.fifth-section-context {
    display: flex;
    flex-direction: column;
    padding: 5% 0% 2% 0%;
}

.fifth-section-context h1 {
    color: #CB2E3A;
    text-align: center;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.fifth-section-context p {
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
}

.fifth-buttons {
    display: flex;
    gap: 12px;
    margin-bottom: 45px;
}

.fifth-buttons button {
    width: 178.29px;
    height: 47.4px;
    border-radius: 8px;
    background: #000;
    border: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 5px 11px 0px rgba(0, 0, 0, 0.10), 0px 19px 19px 0px rgba(0, 0, 0, 0.09), 0px 44px 26px 0px rgba(0, 0, 0, 0.05), 0px 78px 31px 0px rgba(0, 0, 0, 0.01), 0px 122px 34px 0px rgba(0, 0, 0, 0.00);

    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.fifth-context-subsection {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.fifth-context-heading {
    display: flex;
    align-items: baseline;
    gap: 10px;
    width: fit-content;
}

.fifth-context-heading h1 {
    color: #333;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    /* 144.444% */
}

.fifth-context-subsection p {
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.custom-qoute button {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 23px;
    background: #1B1B1B;
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}

.custom-qoute button:hover {
    background: #E31E25
}

/*SIXTH SECTION*/

.cloud-sixth-section-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(234, 0, 41, 0), rgba(234, 0, 41, 0.05));
    padding-bottom: 5%;
}

.sixth-section-context {
    display: flex;
    flex-direction: column;
    padding: 2% 0%;
}

.sixth-section-context h1 {
    color: #E31E25;
    text-align: center;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sixth-section-context p {
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
}

.sixth-section-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
}

.grid-card-sixth p {
    color: #444;
    text-align: center;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.grid-card-sixth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 42px 24px 33px 24px;
    max-width: 341px;
    background: #FFF;
    box-shadow: 1.736px 1.736px 6.075px 0px rgba(0, 0, 0, 0.10), 6.942px 8.678px 11.281px 0px rgba(0, 0, 0, 0.09), 14.753px 19.092px 14.753px 0px rgba(0, 0, 0, 0.05), 26.902px 34.712px 17.356px 0px rgba(0, 0, 0, 0.01), 41.655px 53.804px 19.092px 0px rgba(0, 0, 0, 0.00);

}

.grid-card-sixth h1 {
    color: #333;
    text-align: center;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    padding-top: 16px;
    padding-bottom: 6px;
}

.sixth-section-mob {
    display: none;
}


/*SEVENTH SECTION*/
.cloud-seventh-section-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

.seventh-logos {
    display: flex;
    gap: 122px;
}

@media (max-width:1681px) {}

@media (max-width: 1601px) {}

@media (max-width: 1585px) {}

@media (max-width: 1537px) {}

@media (max-width: 1441px) {}

@media (max-width: 1401px) {}

@media (max-width: 1381px) {}

@media (max-width: 1367px) {}

@media (max-width: 1281px) {
    .cloud-first-section-main {
        padding-top: 20%;
    }

    .seventh-logos {
        gap: 100px;
    }

    .point.selected {
        max-height: 190px !important;
    }
}

@media (max-width: 1025px) {
    .cloud-first-section-main {
        padding-top: 30%;
        padding-bottom: 5%;
    }

    .infinite {
        width: 100%;
        height: 100%;
    }

    .card-main {
        max-width: 290px;
    }

    .point {
        max-height: 185px !important;
    }

    .fifth-context {
        padding-left: 3%;
        padding-right: 3%;
    }

    .grid-card-sixth {
        padding: 42px 8px 33px 8px;
        max-width: 300px;
    }

    .sixth-section-grid {
        gap: 35px;
    }

    .seventh-logos {
        gap: 60px;
    }
}

@media (max-width: 915px) {
    .nav-signs {
        display: block;
    }

    .infinitemob {
        margin-left: 25%;
    }

    .cloud-first-section-main::before {
        height: 575px;
        width: 575px;
    }

    .cloud-first-section-main {
        flex-direction: column;
        padding-top: 30%;
    }

    .first-section-left,
    .first-section-right {
        width: 100%;
    }

    .infinitemob {
        display: block;
    }

    .infinite {
        display: none;
        width: 100%;
    }

    .second-context {
        padding: 40px 20px;
        width: 100%;
    }

    .card-main {
        max-width: 275px;
    }

    /* .second-cards-web {
        display: none;
    }

    .second-cards-mob {
        display: block;
        position: relative;
        width: 80%;
        margin: auto;
        z-index: 1;
    } */

    .third-context {
        width: 100%;
    }

    .third-points {
        flex-direction: column;
        padding: 0px 36px;
        gap: 40px;
    }

    .third-points-right,
    .third-points-left {
        width: 100%;
    }

    .cloud-fourth-section-main {
        padding: 0px 20px;
    }

    .fourth-section-grid {
        gap: 22px;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 60px;
    }

    .grid-card {
        background: #FFFF;
        box-shadow: 1.736px 1.736px 6.075px 0px rgba(0, 0, 0, 0.10), 6.942px 8.678px 11.281px 0px rgba(0, 0, 0, 0.09), 14.753px 19.092px 14.753px 0px rgba(0, 0, 0, 0.05), 26.902px 34.712px 17.356px 0px rgba(0, 0, 0, 0.01), 41.655px 53.804px 19.092px 0px rgba(0, 0, 0, 0.00);
        padding: 21px 0px 20px 0px;
    }

    .cloud-fifth-section-main {
        padding: 0px 20px 40px 20px;
    }

    .sixth-section-grid {
        gap: 22px;
        grid-template-columns: repeat(2, 1fr);
    }

    .seventh-logos {
        /* display: grid; */
        grid-template-columns: repeat(3, 1fr);
        gap: 2px;
    }

    .first-section-left h1 {
        font-size: 26px;
    }

    .first-section-left p {
        font-size: 15px;
    }

    .cloud-button {
        font-size: 10px;
    }

    .second-context h1 {
        font-size: 20px;
        padding: 0% 15%;
    }

    .second-context p {
        font-size: 15px;
        padding: 12px 24px;
    }

    .card-main {
        box-shadow: none;
        padding: 19px;
        display: flex !important;
    }

    .second-cards-mob .slick-prev,
    .slick-next {
        display: none !important;
    }

    .slick-dots li button:before {
        font-size: 16px;
    }

    .slick-dots li.slick-active button:before {
        color: #E5373D;
        opacity: 1;
    }

    .slick-dots li button {
        color: #D9D9D9;
    }

    /* 
    .second-section-button {
        margin-top: 70px;
    } */

    .slick-dots {
        bottom: -60px;
    }

    .card-main h2 {
        font-size: 13px;
    }

    .card-main p {
        font-size: 11px;
    }

    .third-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 18%;
        padding-right: 18%;
    }

    .third-context p {
        font-size: 15px;
        padding: 12px 24px;
    }

    .point {
        padding: 10px 10px 10px 10px;
        max-height: 150px !important;
    }

    .point-heading h1 {
        font-size: 12px;
        margin-bottom: none;
    }

    .point p {
        font-size: 12px;
        padding-left: 0px;
        padding-top: 11px;
        padding-bottom: 19px;
    }

    .fourth-section-context h1 {
        font-size: 20px;
        padding-top: 40px;
    }

    .fourth-section-context p {
        font-size: 15px;
    }

    .grid-card h1 {
        font-size: 14px;
    }

    .grid-card p {
        font-size: 10px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .fifth-section-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .fifth-section-context p {
        font-size: 15px;
    }

    .fifth-buttons button {
        font-size: 12px;
        width: 142.805px;
        height: 37.966px;
    }

    .fifth-context-heading h1,
    .fifth-context-subsection p {
        font-size: 12px;
        margin-bottom: 0;
    }

    .fifth-context-heading img {
        width: 16px;
        height: 12px;
    }

    .custom-qoute button {
        font-size: 10px;
        width: 164px;
        height: 31px;
        padding: 3px 23px;
    }

    .sixth-section-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .sixth-section-context p {
        font-size: 15px;
    }

    .grid-card-sixth h1 {
        font-size: 15px;
        line-height: 22.064px;
        margin-bottom: 0px;
    }

    .grid-card-sixth p {
        font-size: 10px;
    }

    .grid-card-sixth {
        padding: 23px 4px 31px 5px;
    }
}

@media (max-width: 901px) {}

@media (max-width: 885px) {
    .card-main {
        max-width: 250px;
    }

    .infinitemob {
        margin-left: 25%;
    }
}

@media (max-width: 858px) {
    .cloud-first-section-main::before {
        height: 550px;
        width: 550px;
    }
}

@media (max-width: 840px) {
    .cloud-first-section-main::before {
        height: 530px;
        width: 530px;
    }
}

@media (max-width: 821px) {
    .cloud-first-section-main {
        flex-direction: column;
        padding-top: 36%;
    }
}

@media (max-width: 811px) {
    .card-main {
        max-width: 290px;
    }
}

@media (max-width: 801px) {
    .card-main {
        max-width: 236px;
    }
}

@media (max-width: 769px) {
    .second-cards-web {
        gap: 8px;
    }

    .card-main {
        max-width: 240px;
    }

    .cloud-first-section-main:before {
        height: 575px;
        width: 575px;
    }
}

@media (max-width: 713px) {
    .infinitemob {
        margin-left: 20%;
    }

    .card-main {
        max-width: 220px;
    }
}

@media (max-width: 616px) {
    .seventh-logos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 70px;
    }

    .cloud-first-section-main {
        flex-direction: column;
        padding-top: 44%;
    }

    .infinitemob {
        margin-left: 13%;
    }

    .second-cards-web {
        display: none;
    }

    .second-cards-mob {
        display: block;
        position: relative;
        width: 80%;
        margin: auto;
        z-index: 1;
    }

    .second-section-button {
        margin-top: 70px;
    }

    .grid-card-sixth {
        max-width: 245px;
    }
}

@media (max-width: 601px) {
    .cloud-first-section-main:before {
        height: 575px;
        width: 575px;
    }

    .cloud-first-section-main:before {
        top: -396px;
        left: -306px;
    }
}

@media (max-width: 541px) {
    .cloud-first-section-main {
        flex-direction: column;
        padding-top: 50%;
    }
}

@media (max-width: 501px) {
    .cloud-first-section-main {
        flex-direction: column;
        padding-top: 52%;
    }

    .grid-card-sixth {
        max-width: 204px;
    }

    .infinitemob {
        margin-left: 8%;
    }

    .sixth-section-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 65px;
        padding-right: 65px;
    }
}

@media (max-width: 481px) {
    .infinitemob {
        margin-left: 5%;
    }

    .sixth-section-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 60px;
        padding-right: 60px;
    }

    .cloud-first-section-main {
        flex-direction: column;
        padding-top: 55%;
    }

    .fourth-section-grid,
    .sixth-section-grid {
        display: none;
    }

    .fourth-section-mob,
    .sixth-section-mob {
        display: block;
        position: relative;
        width: 80%;
        margin: auto;
        z-index: 1;
        padding-bottom: 35%;
    }

    .fourth-section-mob .slick-prev,
    .slick-next {
        display: none !important;
    }

    .sixth-section-mob .slick-prev,
    .slick-next {
        display: none !important;
    }

    .grid-card,
    .grid-card-sixth {
        box-shadow: none !important;
        display: flex !important;
        border-radius: 20px;
    }

    .grid-card-sixth {
        max-width: 290px;
    }
}

@media (max-width: 449px) {
    .cloud-first-section-main {
        flex-direction: column;
        padding-top: 60%;
    }
}

@media (max-width: 431px) {
    .infinitemob {
        margin-left: 0%;
    }

    .cloud-first-section-main::before {
        height: 575px;
        width: 575px;
    }

    .cloud-first-section-main {
        flex-direction: column;
        padding-top: 65%;
    }

    .first-section-left,
    .first-section-right {
        width: 100%;
    }

    .second-context {
        padding: 40px 20px;
        width: 100%;
    }

    .second-cards-web {
        display: none;
    }

    .second-cards-mob {
        display: block;
        position: relative;
        width: 80%;
        margin: auto;
        z-index: 1;
    }

    .third-context {
        width: 100%;
    }

    .third-points {
        flex-direction: column;
        padding: 0px 36px;
        gap: 40px;
    }

    .third-points-right,
    .third-points-left {
        width: 100%;
    }

    .cloud-fourth-section-main {
        padding: 0px 20px;
    }

    .fourth-section-grid {
        gap: 22px;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 60px;
    }

    .grid-card {
        background: #FFFF;
        box-shadow: 1.736px 1.736px 6.075px 0px rgba(0, 0, 0, 0.10), 6.942px 8.678px 11.281px 0px rgba(0, 0, 0, 0.09), 14.753px 19.092px 14.753px 0px rgba(0, 0, 0, 0.05), 26.902px 34.712px 17.356px 0px rgba(0, 0, 0, 0.01), 41.655px 53.804px 19.092px 0px rgba(0, 0, 0, 0.00);
        padding: 21px 0px 20px 0px;
    }

    .cloud-fifth-section-main {
        padding: 0px 20px 40px 20px;
    }

    .sixth-section-grid {
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }

    .seventh-logos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
    }

    .first-section-left h1 {
        font-size: 26px;
    }

    .first-section-left p {
        font-size: 15px;
    }

    .cloud-button {
        font-size: 10px;
    }

    .second-context h1 {
        font-size: 20px;
        padding: 0% 15%;
    }

    .second-context p {
        font-size: 15px;
        padding: 12px 4px;
    }

    .card-main {
        box-shadow: none;
        padding: 19px;
        display: flex !important;
    }

    .second-cards-mob .slick-prev,
    .slick-next {
        display: none !important;
    }

    .slick-dots li button:before {
        font-size: 16px;
    }

    .slick-dots li.slick-active button:before {
        color: #E5373D;
        opacity: 1;
    }

    .slick-dots li button {
        color: #D9D9D9;
    }

    .second-section-button {
        margin-top: 70px;
    }

    .slick-dots {
        bottom: -60px;
    }

    .card-main h2 {
        font-size: 13px;
    }

    .card-main p {
        font-size: 11px;
    }

    .third-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 18%;
        padding-right: 18%;
    }

    .third-context p {
        font-size: 15px;
        padding: 12px 4px;
    }

    .point {
        padding: 10px 10px 10px 10px;
        max-height: 150px !important;
    }

    .point-heading h1 {
        font-size: 12px;
        margin-bottom: none;
    }

    .point p {
        font-size: 12px;
        padding-left: 0px;
        padding-top: 11px;
        padding-bottom: 19px;
    }

    .fourth-section-context h1 {
        font-size: 20px;
        padding-top: 40px;
    }

    .fourth-section-context p {
        font-size: 15px;
    }

    .grid-card h1 {
        font-size: 14px;
    }

    .grid-card p {
        font-size: 10px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .fifth-section-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .fifth-section-context p {
        font-size: 15px;
        padding-left: 45px;
        padding-right: 45px;
    }

    .fifth-buttons button {
        font-size: 12px;
        width: 142.805px;
        height: 37.966px;
    }

    .fifth-context-heading h1,
    .fifth-context-subsection p {
        font-size: 12px;
        margin-bottom: 0;
    }

    .fifth-context-heading img {
        width: 16px;
        height: 12px;
    }

    .custom-qoute button {
        font-size: 10px;
        width: 164px;
        height: 31px;
        padding: 3px 23px;
    }

    .sixth-section-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .sixth-section-context p {
        font-size: 15px;
    }

    .grid-card-sixth h1 {
        font-size: 15px;
        line-height: 22.064px;
        margin-bottom: 0px;
    }

    .grid-card-sixth p {
        font-size: 10px;
    }

    .grid-card-sixth {
        padding: 23px 4px 31px 5px;
    }
}

@media (max-width: 415px) {
    .point {
        padding: 10px 10px 10px 10px;
        max-height: 170px !important;
    }
}

@media (max-width: 412px) {}

@media (max-width: 401px) {

    .fourth-section-mob,
    .sixth-section-mob {
        display: block;
        position: relative;
        width: 85%;
        margin: auto;
        z-index: 1;
        padding-bottom: 35%;
    }
}

@media (max-width: 394px) {}

@media (max-width: 391px) {}

@media (max-width: 386px) {
    .point {
        padding: 10px 10px 10px 10px;
        max-height: 170px !important;
    }

    .fourth-section-mob,
    .sixth-section-mob {
        display: block;
        position: relative;
        width: 88%;
        margin: auto;
        z-index: 1;
        padding-bottom: 35%;
    }

    .third-context h1 {
        font-size: 20px;
        padding-top: 40px;
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media (max-width: 376px) {}

@media (max-width: 361px) {
    .grid-card-sixth h1 {
        font-size: 12px;
        line-height: 22.064px;
        margin-bottom: 0px;
    }

    .cloud-fourth-section-main {
        padding: 0px 15px;
    }

    .grid-card {
        padding: 21px 14px 20px 14px;
    }

    .point {
        padding: 10px 10px 10px 10px;
        max-height: 190px !important;
    }

    .infinitemob {
        margin-left: 1%;
    }

    .second-cards-mob {
        display: block;
        position: relative;
        width: 85%;
        margin: auto;
        z-index: 1;
    }

    .fourth-section-mob,
    .sixth-section-mob {
        display: block;
        position: relative;
        width: 90%;
        margin: auto;
        z-index: 1;
        padding-bottom: 35%;
    }

    .grid-card p {
        font-size: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }
}


@media (max-width: 320px) {

    .fourth-section-grid {
        gap: 9px;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 60px;
    }

    .point.selected {
        max-height: 220px !important;
    }
}

@media (max-width:280px) {
    .fifth-buttons button {
        font-size: 12px;
        width: 126.805px;
        height: 37.966px;
    }

    .fourth-section-grid,
    .sixth-section-grid {
        display: none;
    }

    .fourth-section-mob,
    .sixth-section-mob {
        display: block;
        position: relative;
        width: 80%;
        margin: auto;
        z-index: 1;
        padding-bottom: 35%;
    }

    .fourth-section-mob .slick-prev,
    .slick-next {
        display: none !important;
    }

    .sixth-section-mob .slick-prev,
    .slick-next {
        display: none !important;
    }

    .grid-card,
    .grid-card-sixth {
        box-shadow: none;
        display: flex !important;
    }

    .cloud-first-section-main::before {
        height: 480px;
        width: 480px;
    }

    .point {
        padding: 10px 10px 10px 10px;
        max-height: 240px !important;
    }
}