.playroom-wrapper{
    background: #E31E25;
    padding:6rem 0;
    margin-top: 160px;
    margin-bottom:160px;
    position: relative;
}
.playroom-wrapper h3{
    
    font-weight: 700;
    font-size: 32px;
 
    text-align: center;
    text-transform: uppercase;
    
    color: #FFFFFF;
}

.playroom-f-p{

    font-size: 18px;
    width: 1078px;
    max-width: 80%;
    margin: auto;
    text-align: center;
    
    color: #FFFFFF;
}

.playroom-flex{
    display: flex;
    align-items: center;
    max-width: 80%;
    margin-top:3%;
    margin-left: auto;
    margin-right: auto;
    color: white;

}
.playroom-flex > div{
    width: 50%;
}

.play-btn-wrapper{
    background: white;
    filter: drop-shadow(0px 61px 25px rgba(45, 6, 7, 0.01)) drop-shadow(0px 35px 21px rgba(45, 6, 7, 0.05)) drop-shadow(0px 15px 15px rgba(45, 6, 7, 0.09)) drop-shadow(0px 4px 8px rgba(45, 6, 7, 0.1)) drop-shadow(0px 0px 0px rgba(45, 6, 7, 0.1));
    border-radius: 8px;
    padding: 20px 30px;
    width: fit-content;
  margin-bottom: 30px;
}
.play-btn-wrapper p{
    color: black;
    font-weight: 500;
font-size: 16px;
}
.ply-btn-bg{
    background: black;
    border-radius: 50%;

    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-play-btn-l-d{
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all .5s ease-out;
}
.play-btn-lines-main{
    display: flex;
    gap: 3px;
    align-items: center;
}
.play-btn-lines{
    background: black;
    width: 5px;
    border-radius: 34px;
   
}
.play-btn-lines.active{
    background: #E31E25;
}
.flex-play-btn-l-d.active .play-btn-lines.inactive{
    background: rgba(227, 30, 37, 0.3);
}
.line1{
    height: 8px;
}
.line2{
    height: 16px;
}
.line3{
    height: 24px;
}
.line4{
    height: 16px;
}
.line5{
    height: 8px;
}
.line6{
    height: 8px;
}
.line7{
    height: 16px;
}
.line8{
    height: 24px;
}
.line9{
    height: 16px;
}

.line10{
    height: 8px;
}
.line11{
    height: 8px;
}
.line12{
    height: 16px;
}
.line13{
    height: 24px;
}
.line14{
    height: 16px;
}
.line15{
    height: 8px;
}
.line16{
    height: 8px;
}
.line17{
    height: 16px;
}
.line18{
    height: 24px;
}
.line19{
    height: 16px;
}
.line20{
    height: 8px;
}
.btn-play-wise.active{
    background: #FFFFFF;
    color: black;


}
.btn-play-wise{
    background: black;
box-shadow: 0px 78px 31px rgba(0, 0, 0, 0.01), 0px 44px 26px rgba(0, 0, 0, 0.05), 0px 19px 19px rgba(0, 0, 0, 0.09), 0px 5px 11px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
border-radius: 8px;
border: none;
height: 46px;
width: 170px;
text-transform: uppercase;
font-weight: 500;
color: white;
font-size: 16px;
}
.play-wise-btn-flex{
    display: flex;gap: 20px;
margin-bottom: 40px;
}

.playroom-flex-right-f-p{
    font-size: 18px;

text-transform: capitalize;

color: #FFFFFF;
margin-bottom: 40px;
}

.list-playroom li{
list-style: disc;
font-size: 16px;

text-transform: capitalize;

color: #FFFFFF;
margin-bottom: 10px;
}
.list-playroom{
    padding: 0 20px;
}
.l-p-playroom{
    font-weight: 600;
font-size: 14px;

text-transform: capitalize;

color: #FFFFFF;
margin-top: 60px;
}
.call-wrapper-playroom{
    background: white;
    width: fit-content;
    padding: 5px 10px 5px 5px;
    border-radius: 30px;
}
.playwise-m-img-call-w{
    background: #E31E25;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.call-img-wrapper-playroom{
    display: flex;
    align-items: center;
    gap: 10px;
}
.call-img-wrapper-playroom p{
    color: #E31E25;
    margin-bottom: 0;
    font-weight: 600;
}
.web-d-none-btn{
    display: none;
}
.flex-play-btn-l-d.active{
    transform: scale(1.1);
}
.flex-play-btn-l-d.active .ply-btn-bg{
   background: #E31E25;
}
@media (max-width:650px){

    .playroom-wrapper h3{
        font-size: 20px;
    }
    .playroom-f-p{
        font-size: 15px;
    }
    .mob-d-none-btn{
        display: none;
    }
    .web-d-none-btn{
        display: flex;
        max-width: 100%;
        margin: 20px auto;
        justify-content: center;
    }
    .btn-play-wise{
        font-size: 12px;
    }
    .playroom-flex > div{
width: 100%;
    }
    .playroom-flex{
        flex-wrap: wrap;
    }
    .play-btn-wrapper{
        margin-left: auto;
        margin-right: auto;
    }
    .playroom-flex-left{
        margin-top: 20px;
    }
    .l-p-playroom{
        margin-bottom: 20px;
    }
    .playroom-wrapper{
        margin-bottom: 80px!important;
    }
    .test-btmbr{
        display: none;
    }
    .short-btmbr{
        display: block!important;
        margin-bottom: 80px;
        width: 100%;
    }
}
.test-btmbr{
    margin-bottom: 160px;
    width: 100%;
}
.short-btmbr{
    display: none;
}

