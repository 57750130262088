
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    --orangeDark: #FF882D;
    --pink: #F6D5BC;
    --yellowLight: #FCE700;
    --yellowDark: #FAC213;
    --green: #E0EBCC;
    --blue: #C9E7E1;
    --black: #292929;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
body{
  font-family: 'Inter', sans-serif;
    margin: 0;
    height: 100%;
    background: white;
 
}
html {
  
    font-size: 62.5%;
    box-sizing: border-box;
  }
  img{
    max-width: 100%;
  }
  a{
    color: lightslategray;
    font-size: 1.8rem;
  }
  
  a:active {
    color: inherit;
    background: inherit;
  }
 
  .px-40{
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .px-20{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-70{
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .px-150{
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .px-10{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .px-200{
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .py-35{
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-70{
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .py-150{
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .py-20{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-30{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  ul li{
    list-style:none;
  }
  h1{
    font-size: 4.6rem;
    line-height: 1.5;
  }
  h2{
    font-size: 4.6rem;
    line-height: 1.5;
  }
  p{
    font-size: 1.8rem;
    line-height: 1.3;

  }
  button{
    cursor: pointer;
  
  }

  @media (max-width:1366px) {
    .modal-dialog{
      scale: 0.8;
      display: flex;
      align-items: center;
    }
    
  }
  @media (max-width:600px){
    h1{
      font-size: 2.6rem;
     
    }
    h2{
      font-size: 2.6rem;
   
    }
    .px-150{
      padding-left: 3rem;
      padding-right: 3rem;
    }
    .px-200{
      padding-left: 6rem;
      padding-right: 6rem;
    }
    .px-70{
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .py-150{
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }