.custom-ul {
  margin-left: auto;
}

.navbar {
  padding: 0;
  max-width: 90%;
  margin: auto;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 1.6rem;
  margin-right: 14px;
  color: black;
  font-weight: 400;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #e31e25;
}

.nav-mob-show {
  display: none;
}

.nav-mob-show a#basic-nav-dropdown\ dropdown-modal {
  color: black;
}

.nav-logo-img {
  width: 150px;
}

.custom-ul li a {
  font-size: 1.3rem;
  margin-right: 40px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
}

.c-nav-p {
  background: transparent;
  position: absolute;
  width: 100%;
  top: 45px;
  z-index: 9999;
}

.nav-c-p {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.login-c {
  font-size: 1.6rem;
  cursor: pointer;
  border: none;
  height: 50px;
  width: 200px;
  background: #050708;
  margin-left: 8px;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-tracking-login-c {
  font-size: 1.6rem;
  cursor: pointer;
  border: none;
  height: 50px;
  width: 200px;
  background: #fff;
  margin-left: 8px;
  color: #333333;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn-pdf {
  margin-right: 18px;
}

.login-c:hover {
  background: #e31e25;
  color: white;
  text-decoration: none;
}

.nav-link {
  color: black;
  padding: 0;
}

.hamicon {
  font-size: 2rem;
}

.humburger-desktop {
  cursor: pointer;
}

/* modal nav */
.custom-width-m {
  max-width: 100vw;
  height: 100vh;
}

.custom-width-m .modal-header {
  border: none;
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
}

.custom-width-m .modal-content {
  background: transparent;
  color: white;
}

.custom-body-model {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height: 80vh;
  margin-top: 30px;
}

.modal {
  padding-left: 0 !important;
}

.modal-backdrop.show {
  opacity: 0.9;
}

.cus-close {
  font-size: 20px;
  background: #d9d9d9;
  color: black;
  height: 35px;
  width: 35px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-main-ul li {
  margin-bottom: 15px;
}

.modal-main-ul {
  padding-right: 30px;
}

.modal-nav-items a {
  color: white;
}

a#basic-nav-dropdown\ dropdown-modal {
  color: white;
}

.c-nav-p .dropdown-toggle::after {
  display: none;
}

a.nav-flex-itm.dropdown-item {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}

/* a.nav-flex-itm.dropdown-item:visited {
   color: inherit;
   background: inherit;

} */
a.nav-flex-itm.dropdown-item:active {
  color: inherit;
  background: inherit;
}

.nav-flex-itm p {
  margin-bottom: 0;
  font-size: 1.6rem;
  color: #202020;
  font-weight: 400;
}

.c-nav-p .dropdown-menu {
  width: 30rem;
  border-radius: 30px;
  background: #f4f4f4;
  box-shadow: 0px 14px 6px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05),
    0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 14px;
  border: none;
}

.c-nav-p .navbar-toggler {
  background: #ea0029;
}

.nav-signs {
  display: none;
}

.call-tracking-nav-text {
  color: #fff !important;
}

@media (max-width: 990px) {
  .login-c {
    margin: 0;
  }

  .nav-c-p {
    padding-bottom: 4rem;
  }

  .humburger-desktop {
    margin-bottom: 10px;
  }

  .nav-desktop-show {
    display: none;
  }

  .nav-mob-show {
    display: block;
  }

  .navbar-light .navbar-nav .nav-link {
    margin-bottom: 16px;
  }

  .login-c {
    margin-bottom: 10px;
    width: 100%;
    background: #ea0029;
  }

  div#basic-navbar-nav {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 25px 10px rgba(0, 0, 0, 0.01),
      0px 14px 9px rgba(0, 0, 0, 0.05), 0px 6px 6px rgba(0, 0, 0, 0.09),
      0px 2px 3px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  }

  .c-nav-p .dropdown-menu {
    margin-bottom: 10px;
  }

  .navbar-toggler {
    font-size: 1.8rem;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.10)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.09)) drop-shadow(0px 13px 8px rgba(0, 0, 0, 0.05)) drop-shadow(0px 24px 9px rgba(0, 0, 0, 0.01)) drop-shadow(0px 37px 10px rgba(0, 0, 0, 0.00));
  }

  .call-tracking-nav-text {
    color: #000 !important;
  }

  .call-tracking-login-c {
    margin-bottom: 10px;
    width: 100%;
    background: #ea0029;
    color: #fff !important;
  }
}