@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.callTracking {
    background: #101010;
    padding-top: 10%;
}

.trackingWave {
    width: 100%;
}

.herosec-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    position: relative;
}

.hero-gradient {
    position: absolute;
    width: 258.352px;
    height: 988.01px;
    transform: rotate(-56.041deg);
    flex-shrink: 0;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.10);
    filter: blur(120px);
    left: 0;
    top: -150%;
}


.hero-ellipse {
    width: 12px;
    height: 12px;
    background: #FB434A;
    border-radius: 100%;

}

.herosec-first {
    display: flex;
    align-items: baseline;
    gap: 12px;
    padding: 10px 20px 10px 16px;
    border-radius: 24px;
    border: 1px solid var(--White, #FFF);
    opacity: 0.8;
}

.herosec-first p {
    color: var(--White, var(--White, #FFF));
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 0px;
    /* 21.6px */
}

.herosec-second h2 {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    /* 96px */
    background: linear-gradient(180deg, #FFFFFF 100%, #7F7F7F 0%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.herosec-second h2 span {
    background: linear-gradient(180deg, #FFFFFF 100%, #7F7F7F 0%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Nunito Sans";
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-transform: uppercase;
}

.herosec-second p {
    color: #CFCFCF;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.herosec-buttons {
    display: flex;
    gap: 16px;
    align-items: center;
}

.herosec-buttons .signup {
    border-radius: 8px;
    padding: 14px 32px;
    border-radius: 8px;
    border: 1px solid var(--White, #FFF);
    box-shadow: 0px 16px 4px 0px rgba(0, 0, 0, 0.00), 0px 10px 4px 0px rgba(0, 0, 0, 0.01), 0px 6px 3px 0px rgba(0, 0, 0, 0.05), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    background: none;
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.5s ease-in-out;
}

.herosec-buttons .signup:hover {
    border-radius: 8px;
    color: #ADADAD;
    border: 1px solid var(--White, Red);
    box-shadow: 0px 16px 4px 0px rgba(0, 0, 0, 0.00), 0px 10px 4px 0px rgba(0, 0, 0, 0.01), 0px 6px 3px 0px rgba(0, 0, 0, 0.05), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
}

.herosec-buttons .getADemo {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;


    border-radius: 8px;
    background: var(--G1, linear-gradient(180deg, #3C3A3A 0%, #666464 100%));
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 0.5s ease-in-out;
}

.herosec-buttons .getADemo:hover {
    border-radius: 8px;
    background: var(--g2, linear-gradient(180deg, #E01E26 0%, #5B0F0F 100%));
}

.graphSection-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5% 0% 5% 5%;

}

.graphSection-left,
.reporting-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
    width: 40%;
}


.graphSection-left-context,
.reporting-right-context {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
}

.graphSection-left-context h2,
.reporting-right-context h2 {
    color: var(--White, var(--White, #FFF));
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 57.6px */
}

.graphSection-left-context p,
.reporting-right-context p {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.graphSection-left button,
.reporting-right button {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background-color: #FFF;
    transition: all .25s ease-in-out;

    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    border: none;
    color: rgba(102, 100, 100, 1);
}

.graphSection-left button:hover,
.reporting-right button:hover {
    border-radius: 8px;
    background: var(--g2, linear-gradient(180deg, #E01E26 0%, #5B0F0F 100%));
    color: var(--White, #FFF);
}


.framwork-main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 5% 5% 5%;
    gap: 50px;
    overflow: hidden;
}

.framework-gradient {
    position: absolute;
    width: 258.352px;
    height: 988.01px;
    transform: rotate(56.041deg);
    flex-shrink: 0;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.10);
    filter: blur(120px);
    right: 0;
    top: 0%;
}

.framework-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.framework-context h2 {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 62.4px */

    color: var(--White, var(--White, #FFF));
}

.framework-context p {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.framework-content {
    display: flex;
    width: 100%;
    align-items: center;
}

.content-left,
.content-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.content-left p,
.content-right p {
    color: var(--White, var(--White, #FFF));
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 19.2px */
}

.stroke {
    height: 1px;
    background: #E01E26;
    opacity: 0.7;
    width: 45%;
}

.circle-main {
    position: relative;
    height: 700px;
    width: 40%;
    display: flex;
    justify-content: center;
}

.content-circle-first,
.content-circle-second,
.content-circle-third {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 414px;
    height: 414px;
    text-align: center;
    border-radius: 414px;
    padding: 90px;
}

.content-circle-first h3,
.content-circle-second h3,
.content-circle-third h3 {
    color: var(--White, var(--White, #FFF));
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 19.8px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 23.76px */
}

.content-circle-first p,
.content-circle-second p,
.content-circle-third p {
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    /* 19.6px */
}

.content-circle-first {
    background: linear-gradient(0deg, rgba(184, 57, 57, 0.80), rgba(0, 0, 0, 0.00));
}

.content-circle-second {
    background: linear-gradient(-98deg, rgba(184, 57, 57, 0.80), rgba(0, 0, 0, 0.00));
    position: absolute;
    left: -6%;
    bottom: -2%;
}

.content-circle-third {
    background: linear-gradient(98deg, rgba(184, 57, 57, 0.80), rgba(0, 0, 0, 0.00));
    position: absolute;
    right: -6%;
    bottom: -2%;
}

.reporting-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5% 5% 5% 5%;
    position: relative;
    overflow: hidden;
}

.reporting-gradient {
    position: absolute;
    width: 199.347px;
    height: 565px;
    transform: rotate(56.041deg);
    flex-shrink: 0;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.10);
    filter: blur(120px);
    right: 0;
    top: 0%;
}

.reporting-left {
    position: relative;
}

.hover-image {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 1s ease-in-out;
}

.default-image {
    transition: all 1s ease-in-out;
}

.reporting-left:hover .default-image {
    opacity: 0;
}

.reporting-left:hover .hover-image {
    opacity: 1;
}

.seamless-lead-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    gap: 100px;

}

.seamless-lead-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.seamless-lead-context h2 {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 57.6px */
    color: var(--White, var(--White, #FFF));

}

.seamless-lead-context p {
    color: #CFCFCF;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.seamless-animation canvas {
    width: 100% !important;
    position: absolute;
    left: 0%;
}

.seamless-lead-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.seamless-card {
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 16px;
    padding: 16px 24px 56px 24px;
    background: linear-gradient(180deg, #3C3A3A 0%, #000 100%);
    transition: all .5s ease-in-out;
}

.seamless-card:hover {
    border-radius: 16px;
    background: var(--G4, linear-gradient(180deg, #B83939 0%, #000 100%));
}

.seamless-card-upper img {
    transition: all .5s ease-in-out;
}

.seamless-card:hover .seamless-card-upper img {
    scale: 1.2;
}

.seamless-card-upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seamless-card-lower {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.seamless-card-upper p {
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 48px */
}

.seamless-card-lower h3 {
    font-family: "Nunito Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 26.4px */

    background: linear-gradient(270deg, rgba(255, 255, 255, 1), rgba(127, 127, 127, 1));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.seamless-card-lower p {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.seamless-content-left,
.seamless-content-right {
    display: flex;
    flex-direction: column;
    z-index: 1;
    gap: 32px;
}

.plans-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
}

.plans-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.plans-context h2 {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 57.6px */

    background: linear-gradient(180deg, #FFF 0%, #7F7F7F 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.plans-context p {
    color: #CFCFCF;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.plans-context-button {
    display: flex;
    align-items: center;
    gap: 40px;
}

.plans-context-button p {
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

input[type=checkbox],
input[type=radio],
.p-component {
    width: 88px;
    height: 48px;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: red;
    width: 3.25rem;
    height: 3.25rem;
    top: 29%;
}

.p-inputswitch.p-highlight .p-inputswitch-slider:before {
    transform: translateX(4.25rem);
}

.p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover) .p-inputswitch-slider,
.p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover).p-highlight .p-inputswitch-slider,
.p-inputswitch.p-highlight .p-inputswitch-slider {
    background: #FFF;
}


.plansCard-main {
    display: flex;
    align-items: flex-end;
    gap: 48px;
    padding: 5% 8%;
}

.plan-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px 24px 40px;
    gap: 32px;
    border-radius: 26px;
    background: var(--card, linear-gradient(180deg, #3C3A3A 0%, #000 100%));
}

.card-special {
    background: linear-gradient(180deg, #B83939 0%, #4F1111 100%);
    gap: 52px;
    position: relative;
}

.card-special::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background-image: url(../../images/CallTracking/plan-sec.png);
    background-repeat: no-repeat;
    border-top-left-radius: 24px;
    width: 100%;
    height: 20%;
}

.plancard-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.plancard-header h3 {
    color: var(--White, #FFF);
    font-family: "Nunito Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
}

.plancard-header p {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    /* 160% */
}

.plancard-header h4 {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.header-priceTag {
    display: flex;
    align-items: center;
    gap: 16px;
}

.header-priceTag h2 {
    color: var(--White, #FFF);
    font-family: "Nunito Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
}

.header-priceTag span {
    color: var(--White, #FFF);
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.card-stroke {
    height: 1px;
    background: #FFF;
    width: 100%;
}

.plancard-bullets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.plancard-bullet-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.plancard-bullet-inner h3 {
    color: var(--White, #FFF);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 250% */
}

.plancard-bullet-inner p {
    color: var(--White, #FFF);
    text-align: right;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 285.714% */
}

.plancard-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.plancard-button button {
    display: flex;
    padding: 11px 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: center;

    border-radius: 12px;
    border: 1px solid var(--White, #FFF);

    color: var(--White, #FFF);
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 200% */

    background: none;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.plancard-button button:hover {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(207, 207, 207, 1);
    border-radius: 12px;

    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;

    color: red !important;
}

.bottom-main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;

    background-image: url(../../images/CallTracking/div-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.bottom-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(58.52% 45.17% at 51.63% 46.52%, rgba(8, 6, 17, 0.10) 0%, #010101 100%);
    z-index: 1;
}

.bottom-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    z-index: 2;
}

.bottom-context h2 {
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bottom-context p {
    color: #F0F0F0;
    text-align: center;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.bottom-context button {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 23px;
    background: var(--White, #FFF);

    color: #181818;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    transition: all .5s ease-in-out;
}

.bottom-context button:hover {
    background: rgba(227, 30, 37, 1);
    color: #fff;
}