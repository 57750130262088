
.grid-useai .slick-prev:before,.grid-useai .slick-next:before{
    display: none;
}

.grid-useai .slick-prev,.grid-useai .slick-next{
   background: none!important;
   
   padding: 0!important;
   top: 50%;
}
.grid-useai .slick-prev {
    left: -7%;
}
.grid-useai .slick-next {
    right: -5%;
}
.grid-useai .slick-dots li button:before{
    font-size: 10px;
}
.grid-useai .slick-dots li.slick-active button:before{
    color: #E31E25;
    opacity: 1;
    font-size: 16px;
}

.grid-useai{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
    gap: 14px;
    max-width: 80%;
    margin: auto;
}
.UseAi-main{
    max-width: 90%;
    margin: auto;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 5%;
}
.UseAi-main h2{
    color: #E31E25;
    font-size: 3.2rem;
    font-weight: 700;
}
.UseAi-main p{
    max-width: 700px;
    margin: auto;
    font-size: 1.8rem;
}
.useai-img-d{
    /* background: #D6D6D6; */
    border-radius: 50%;
    width:65%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.grid-useai .slick-dots{
    bottom: -48px;
}
/* .useai-c-d:hover .useai-img-d{
    
    background: white;
} */
.useai-c-d {
    display: flex!important;
    flex-direction: column;
  
    padding: 20px 10px;
    border-radius: 8px;
    align-items: center;
    gap: 38px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
    max-height: 289px;
}
.useai-c-d:hover{
    background: #FFB5B8;
}
.useai-img-d img{
    /* width: 100px; */
    height: 150px;
    object-fit: contain;
    margin-top: 22.09px;
}
.useai-c-d h3{
    font-size: 1.4rem;
  
    margin-bottom: 0;
    margin-top: 20px;
    text-align: center;
}
.useai-c-d p{
    font-size: 1.2rem;
    text-align: center;
}

@media (max-width:650px){
    .UseAi-main{
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .UseAi-main p {
        max-width: 90%;
      
    }
    .useai-c-d{
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 8px 8px 0px rgba(0, 0, 0, 0.09), 0px 18px 11px 0px rgba(0, 0, 0, 0.05), 0px 32px 13px 0px rgba(0, 0, 0, 0.01), 0px 51px 14px 0px rgba(0, 0, 0, 0.00);
    }
}