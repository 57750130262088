.ytp-cued-thumbnail-overlay-image {
  background-image: unset !important;
}

.finance-main-top-flex-left {
  scale: 0.8;
  padding-bottom: 5%;
}

.sad-idus {
  cursor: pointer;
  border: none;
  /* padding: 12px 25px; */
  background: #e31e25;
  margin-top: 4%;
  color: white;
  border-radius: 8px;
  height: 44px;
  width: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
}

.finance-main-top-flex-right button:hover {
  color: white;
  text-decoration: none;
  background-color: #000000 !important;
}

.finance-main-top-flex {
  display: flex;
  align-items: center;
  position: absolute;
  max-width: 90%;
  margin-left: 165px;
  margin-right: auto;
  height: 100%;
  margin-top: 3%;
}

.finance-main-top-flex-right h3 {
  font-size: 27px;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
  padding-bottom: 2%;
}

.finance-main-top-flex-right h3::before {
  position: absolute;
  content: "";
  width: 30%;
  height: 2px;
  bottom: 0;
  background: black;
}

.finance-main-top-flex-right p {
  padding-top: 2%;
  line-height: 1.5;
  font-size: 1.8rem;
}

.finance-spanheading {
  font-size: 40px;
  font-weight: 700;
}

.finance-main-top-flex>div {
  width: 50%;
  margin-right: 70px;
}

.inbound-outbound-wrapper {
  display: flex;
  max-width: 90%;
  margin: auto;
  margin-top: 65px;
  gap: 14px;
  padding-top: 75px;
  flex-direction: column;
}

#automate-tab {
  display: flex;
  max-width: 90%;
  margin: auto;
  margin-top: 65px;
  gap: 14px;
  flex-direction: column;
  margin-bottom: 189px;
}

#video-tab {
  display: flex;
  max-width: 90%;
  margin: auto;
  gap: 14px;
  flex-direction: column;
  margin-bottom: 189px;
}

#endless-tab {
  display: flex;
  max-width: 90%;
  margin: auto;
  gap: 14px;
  flex-direction: column;
  margin-bottom: 189px;
}

.bound-btn-flex button {
  text-transform: uppercase;
  font-weight: 600;
  width: 190px;
  height: 45px;
  border-radius: 8px;
  font-size: 1.6rem;
  color: white;
  box-shadow: 0px 78px 31px rgba(0, 0, 0, 0.01),
    0px 44px 26px rgba(0, 0, 0, 0.05), 0px 19px 19px rgba(0, 0, 0, 0.09),
    0px 5px 11px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border: none;
  background: #000000;
}

.bound-btn-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 4%;
}

.outbound-images-flex {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 4%;
}

.bound-btn-flex .active {
  background: #e31e25 !important;
}

.inbound-outbound-wrapper h3 {
  font-size: 3.2rem;
  font-weight: 700;
  color: #e31e25;
  text-align: center;
  margin-bottom: 12px;
  text-transform: uppercase;

}

.h-poi {
  font-size: 18px;
  max-width: 60%;
  margin: auto;
  text-align: center;
  text-transform: capitalize;
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.slider-vid-in {
  height: 250px;
  margin-left: 25px;
  width: 78%;
  margin-top: 5rem;
  border-radius: 20px;
}

.vids-con {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 20px;

}

.vids-con img {
  width: 100%;
}

.vids-con h5 {
  font-size: 2rem;
  max-width: 83%;
  margin-left: 6%;
  margin-top: 36px;
  color: #2e2e2e;
}

.vids-con p {
  max-width: 400px;
  margin-left: 6%;
  color: #2e2e2e;
  margin-top: 28px;
}

.line-b-hr {
  position: relative;
  margin-bottom: 122px;
  width: fit-content;
}

.line-b-hr::before {
  position: absolute;
  content: "";
  background: #2e2e2e;
  width: 150px;
  height: 1px;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.casestd-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 60px;
  max-width: 70%;
  margin: auto;
  text-align: center;
  align-items: flex-end;
}

.case-std-chld {
  padding: 15px;
}

.case-std-chld:hover {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.09), 0px 2px 1px 0px rgba(0, 0, 0, 0.05), 0px 4px 2px 0px rgba(0, 0, 0, 0.01), 0px 7px 2px 0px rgba(0, 0, 0, 0.00);
}

.case-std-chld p:nth-of-type(1) {
  margin-top: 30px;
}

.case-std-chld p {
  font-size: 1.1rem;
  margin-bottom: 0;
}

.voicebot-flex {
  display: flex;
  max-width: 70%;
  margin: auto;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.voicebot-btn {
  width: 126px;
  height: 46px;

  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  font-size: 1.8rem;
  color: #242424;
}

.line-b-hr-btn {
  position: relative;
  margin-bottom: 6%;
  width: fit-content;
}

.line-b-hr-btn::before {
  position: absolute;
  content: "";
  background: #2e2e2e;
  width: 150px;
  height: 1px;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.btn-mob-mock {
  text-transform: uppercase;
  font-weight: 600;
  width: 190px;
  height: 45px;
  border-radius: 8px;
  font-size: 1.4rem;
  color: white;
  box-shadow: 0px 78px 31px rgba(0, 0, 0, 0.01),
    0px 44px 26px rgba(0, 0, 0, 0.05), 0px 19px 19px rgba(0, 0, 0, 0.09),
    0px 5px 11px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border: none;
  background: #000000;
}

.btn-mob-mock.active {
  background: #e31e25 !important;
}

.mockup-tabs {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 5%;
}

.mockup-mob-wrapper {
  max-width: 80%;
  margin: auto;
}

.main-mob-mock-p {
  background: rgba(217, 217, 217, 0.7);
  margin-top: 185px;
}

.moc-slider {
  background: url("../../images//industries-finance/mobmockup.png");
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 543px;
  justify-content: flex-end;
  margin-bottom: -100px;
  position: relative;
  z-index: 1;
}

.indus-vid-sli .slick-prev:before,
.indus-vid-sli .slick-next:before {
  display: none;
}

.indus-vid-sli .slick-prev,
.indus-vid-sli .slick-next {
  background: none !important;

  padding: 0 !important;
  top: 35%;
}

.slider-mocks-main-p-flex {
  display: flex;
  align-items: center;
}

.mocks-main-p-flex-left {
  width: 30%;
}

.mocks-main-p-flex-left {
  width: 30%;
}

.mocks-main-p-flex-right {
  width: 70%;
}

/* slider finance start */
.fslider-h-t {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  color: #424aef;
  margin-bottom: 2%;
}

.flsider-b-t {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  max-width: 80%;
  text-align: center;

  color: #000000;
}

.SliderF-wraper {
  width: 70%;

  margin: auto;
  padding-top: 4%;
  padding-bottom: 4%;
}

.SliderF-wraper .slider-main-app .slick-prev {
  left: 0px;
  z-index: 9999;
  /* display: none!important; */
  background: none !important;
  height: 40px !important;
  width: 40px !important;
}

.SliderF-wraper .slider-main-app .slick-prev::before,
.SliderF-wraper .slider-main-app .slick-next::before {
  font-size: 60px;
  color: gray;
  display: none !important;
}

.SliderF-wraper .slider-main-app .slick-next {
  right: 0px;
  z-index: 9999;
  background: none !important;
  height: 40px !important;
  width: 40px !important;
  /* display: none!important; */
}

.SliderF-wraper .slider-main-app .slick-dots li button:before {
  font-size: 10px;
}

.SliderF-wraper .slick-dots {
  position: initial;
  margin-top: 45px;
  margin-bottom: 45px;
  display: none !important;
}

.SliderF-wraper .slick-active button::before {
  color: #000000 !important;
  opacity: 1 !important;
  font-size: 16px !important;
}

.SliderF-wraper .slider-main-p {
  text-align: center;
  transform: scale(1);
  padding: 11px;
  transition: transform 0.5s ease-in-out;
  filter: brightness(0.5);
}

.SliderF-wraper .slick-center .slider-main-p {
  transform: scale(1.1);
  z-index: 1;
  filter: brightness(1);
  display: flex;
  justify-content: center;
}

.SliderF-wraper .slick-slide img {
  width: 100%;
}

.slider-main-p.active img {
  width: 370px;
  height: 410px;

  position: relative;
  top: 27px;
  border-bottom-left-radius: 36px;
  border-bottom-right-radius: 36px;
}

.SliderF-wraper .slider-main-app .slick-slide>div {
  margin: 0 10px;
}

/* .slider-main-app .slick-slide{
 height: 90%;
  
  } */

.SliderF-wraper .slider-main-app .slick-list {
  margin: 0 -20px;
}

.slider-main-app {
  position: relative;
}

img.mobmockup {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: -webkit-fill-available;
}

.SliderF-wraper .center-slider-main {
  margin-top: 5%;
  overflow: hidden;
  /* padding: 3% 0; */
}

.SliderF-wraper .slick-slider.zoom-center .slick-slide>div {
  transform: scale(0.9);
}

.slick-slider .slick-track {
  display: flex;
  align-items: center;
}

.SliderF-wraper .slider-main-mob {
  display: none;
}

.btn-sc-hm {
  margin-top: 5% !important;
  margin-bottom: 0% !important;
}

.ply-stp-fin {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 6px;
}

.ply-stp-fin p {
  margin-bottom: 0;
}

.f-animation {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #e31e25;
}

.red-cls-ac {
  color: #e31e25;
}

.f-animation span {
  color: black;
}

/* slider finance end */
@media (max-width: 1366px) {
  .homebanner-p::before {
    height: 575px;
    width: 575px;
  }
}

@media (max-width: 1250px) {
  .vids-con h5 {
    font-size: 1.8rem;
  }

  .vids-con p {
    font-size: 1.6rem;
  }
}

@media (max-width: 840px) {
  .casestd-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 650px) {

  #financetop {
    height: calc(100vh - 24vh)
  }

  .sad-idus {
    margin-top: 25%;
  }

  .finance-spanheading {
    font-size: 27px;
  }

  .finance-main-top-flex-right h3 {
    font-size: 25px;
  }

  .finance-main-top-flex-left {
    display: none;
  }

  .finance-main-top-flex>div {
    width: 100%;
  }

  .finance-main-top-flex {
    display: block;
    padding-top: 57%;
    margin-left: 50px;
    margin-right: 50px;
    height: 740px;

  }

  .h-poi {
    max-width: 100%;
    font-size: 16px;
  }

  .SliderF-wraper .slick-dots {
    display: block !important;
  }

  .inbound-outbound-wrapper h3 {
    font-size: 22px;
  }

  .outbound-images-flex {
    flex-wrap: wrap;
    padding-top: 45px;
  }

  .casestd-grid {
    grid-template-columns: 1fr 1fr;
    margin-top: 80px;
  }

  .inbound-outbound-wrapper.line-b-hr-btn {
    margin-top: 68px;
    margin-bottom: 44px;
  }

  .main-mob-mock-p {
    margin-top: 30%;
  }

  .btn-mob-mock {
    width: 140px;
    height: 45px;
    font-size: 10px;
  }

  .mockup-tabs {
    margin-bottom: 5%;
  }

  .slider-mocks-main-p-flex {
    align-items: flex-start;
    flex-direction: column;
  }

  .mocks-main-p-flex-left {
    width: 100%;
  }

  .SliderF-wraper {
    width: 100%;
  }

  .slider-main-p.active img {
    /* height: 336px; */
    object-fit: contain;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
  }

  .mockup-mob-wrapper {
    max-width: 90%;
  }

  .SliderF-wraper .slider-main-app .slick-prev {
    left: -4px;
    z-index: 1;
  }

  .SliderF-wraper .slider-main-app .slick-next {
    right: -5px;
  }

  .vids-con img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .indus-vid-sli .slick-prev {
    left: 1px;
    top: 27%;
    z-index: 1;
  }

  .indus-vid-sli .slick-next {
    right: 3px;
    top: 27%;
  }

  .h-poi {
    margin-bottom: 5%;
  }

  .bound-btn-flex {
    margin-bottom: 5%;
  }

  .vids-con h5 {
    margin-top: 5%;
  }

  .slider-mocks-main-p {
    margin-top: 50px;
  }

  .inbound-outbound-wrapper {
    padding-top: 0%;
  }

  img.mobmockup {
    display: none;
  }

  .slider-main-p.active img {
    height: auto;
  }

  .slider-main-p.active video {
    border-radius: 8px;
    height: 290px;
    width: 220px;
  }

  .slider-vid-in {
    margin-top: 0;
    height: 170px;
  }

  #automate-tab {
    margin-bottom: 0px;
    margin-top: 100px;
  }

  #video-tab {
    margin-bottom: 0px;
    margin-top: 100px;
  }

  #endless-tab {
    margin-top: 100px;
    margin-bottom: 0px;
  }

  #industries {
    padding-top: 45px;
  }

  .slider-mocks-main-p iframe {
    border-radius: 36px;
  }
}

@media (max-width:390px) {
  .sad-idus {
    margin-bottom: 15%;
  }
}

@media (max-width: 375px) {
  .main-mob-mock-p {
    margin-top: 88%;
  }

  .inbound-outbound-wrapper {
    margin-top: 37%;
  }

  /* .inbound-outbound-wrapper{
       
        padding-top: 60%;
       
    } */
}

@media (min-width: 1600px) {
  .slider-main-p.active img {
    padding: 0 8px;
  }
}

.btn-primary:hover {
  background-color: #e31e25 !important;
}

@media (max-width: 500px) {
  .casestd-grid {
    grid-template-columns: 1fr 1fr;
    margin-top: 80px;
    max-width: 100%;
  }

  .slider-main-p.active video {
    border-radius: 8px;
  }
}

.submit-modal h1 {
  color: #202020;
  font-family: 'Inter';
  font-size: 29.002px;
  font-style: normal;
  font-weight: 800;
  line-height: 85.523%;
  /* 24.804px */
  letter-spacing: -0.58px;
}

.submit-modal {
  display: flex !important;
  z-index: 9999999;
  padding: 50px;
}