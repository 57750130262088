
/* .brands-home-p button.slick-arrow.slick-prev,.brands-home-p button.slick-arrow.slick-next{
    display: none!important;
}
.brands-home-p button.slick-arrow.slick-prev:before,.brands-home-p button.slick-arrow.slick-next:before{
    display: none!important;
} */
.automating-p .slick-prev:before,.automating-p .slick-next:before{
    display: none;
}

.automating-p .slick-prev,.automating-p .slick-next{
   background: none!important;
   display: none!important;
   padding: 0!important;
   top: 50%;
}
.automating-p .slick-prev {
    left: -7%;
    z-index: 1;
}
.automating-p .slick-next {
    right: -5%;
}

.brands-home-p{
    max-width: 80%;
    margin: auto;
  
}
.brands-home-p h2{
    font-size: 26px;
    text-align: center;
    margin-bottom: 4%;
    color: #050708;
    font-weight: 600;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.slider-brads-c-d{
    /* padding: 10px; */
    /* border: 2px solid #808080; */
    /* border-radius: 50%; */
    display: flex;
    align-items: center;
    /* height: 80px!important;
    width: 80px!important; */
 
}
.slider-brads-c-d img{
    height: 93px;
  
    margin: auto;
    object-fit: contain;
    filter: grayscale(1);
}
.slider-brads-c-d img:hover{
 
    filter: grayscale(0);
}
.img-brands-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.brands-home-p .slick-dots {
    bottom: -48px;
 
}
.brands-home-p .slick-dots li button:before{
    font-size: 10px;
}
.brands-home-p .slick-dots li.slick-active button:before{
    color: #E31E25;
    opacity: 1;
    font-size: 16px;
}
/* .brands-home-p .slick-slide:not(:last-child) {
    margin-right: 10px;
    width: 60px;
    height: 60px;
  } */
  .automating-p{
    background: #E8E8E8;
    position: relative;
    margin: 5% 0;
    padding: 6% 0;
}
.automating-flex{
    display: flex;
    max-width: 90%;
    margin: auto;
 
    align-items: center;
    padding-top: 5%;
}
.automating-flex >div{
    width: 50%;
}
.automating-right{
    position: relative;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.automating-right-right{
    max-width: 387px;
    max-height: 386px;
}
.automating-right-right img {
    position: absolute;
    object-fit: none;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 100%; 
    height: 100%; 
    opacity: 0; 
  }
  .hidden{
    display: none;
  }
  
  .fade-in {
    animation: fadeInAnimation 0.3s ease-in-out forwards; /* Adjust the duration (2s) as needed */
  }
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
.automating-left h2{
    color: #010101;
    font-size: 3.2rem;
    font-weight: 800;
}
.automating-left h3{
font-size: 4.8rem;
color: #010101;
font-weight: 800;
margin-bottom: 32px;
}
.automating-left h3 span{
  
    color: #E31E25;
  border-bottom: 2px solid;
  padding-bottom: 16px;
    }
    .automating-left p{
color: black;
font-size: 2rem;
margin-bottom: 0;
max-width: 520px;
    }
    .bottom-arrow-automating{
        background: #E31E25;
        border-radius: 50%;
        border: 2px solid white;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -24px;
    }
    .btn-btm-arrow{
        background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
    
    }
    .btn-btm-arrow:hover{
        background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
    }
    .btn-btm-arrow:active, .btn-btm-arrow:focus{
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
    }
@media (max-width: 1024px){
  .automating-left h3{
    font-size: 3.9rem;
  }
}
  @media (max-width:650px){
    .brands-home-p h2 {
     margin-top: 1rem;
     font-size: 1.6rem;
     max-width: 100%;
       
    }
    .automating-flex >div{
        width: 100%;
           
       }
       .automating-flex {
         flex-wrap: wrap;
       }
       .automating-right {
         
           justify-content: center;
       }
       .automating-left h3 {
           font-size: 2.5rem;
      
       }
       
       .automating-p .slick-slide.slick-active.slick-current{
        text-align: center;
       }
       .automating-p .slick-slide.slick-active {
        text-align: center;
    }
    .automating-left h2{
        font-size: 1.7rem;
        margin-top: 5%;
    }
    .automating-left p{
        font-size: 15px;
    }
  }

  .img-brands-flex {
    opacity: 0.5 !important;
    transition: 0.5s;

  }
  .img-brands-flex:hover{
    opacity: 1!important;
  }


  @media (max-width: 1800px) and (min-width: 1670px) {
    .helloimage {
      position: absolute;
      left: 20%;
      scale:0.8; /* Corrected syntax */
      top: 28%;
    }
  }
  
  @media (max-width: 1670px) {
    .helloimage {
      position: absolute;
      left: 15%;
      scale: 0.8; /* Corrected syntax */
      top: 28%;
    }
  }
  
  @media (max-width: 1480px) {
    .helloimage {
      position: absolute;
      left: 9%;
       scale: 0.8; 
      top: 28%;
    }
  }
  @media (max-width: 1440px) {
    .helloimage {
      position: absolute;
      left: 9%;
       scale: 0.8; 
      top: 28%;
    }
  }
  @media (max-width: 1350px) {
    .helloimage {
      position: absolute;
      left: 9%;
      scale:0.8; 
      top: 28%;
    }
  }
  @media (max-width: 1250px) {
    .helloimage {
      position: absolute;
      left: 4%;
      scale:0.7; 
      top: 28%;
    }
  }
  @media (max-width: 1150px) {
    .helloimage {
      position: absolute;
      left: -2%;
      scale:0.7; 
      top: 28%;
    }
  }
  @media (max-width: 1000px) {
    .helloimage {
      position: absolute;
      left: -2%;
      scale:0.7; 
      top: 28%;
    }
  }
  @media (max-width: 900px) {
    .helloimage {
      position: absolute;
      left: -15%;
      scale:0.7; 
      top: 28%;
    }
  }
  @media (max-width: 650px) {
    .helloimage {
      position: absolute;
      left: -2%;
      scale:0.7; 
      top: 28%;
    }
  }
  @media (max-width: 500px) {
    .helloimage {
      position: absolute;
      left: -7%;
      scale:0.7; 
      top: 28%;
    }
  }
  @media (max-width: 450px) {
    .helloimage {
      position: absolute;
      left: -2%;
      scale:0.6; 
      top: 28%;
    }
    .automating-right-right img{
      scale: 0.9
    }
  }
  @media (max-width: 420px) {
    .helloimage {
      position: absolute;
      left: -6%;
      scale:0.6; 
      top: 28%;
    }
    .automating-right-right img{
      scale: 0.9
    }
  }
  @media (max-width: 400px) {
    .helloimage {
      position: absolute;
      left: -14%;
      scale:0.6; 
      top: 28%;
    }
    .automating-right-right img{
      scale: 0.9
    }
  }
  [data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos]{
    transition-duration: 1.1s!important;
  }


  