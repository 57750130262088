.sademo-img-modal {
  width: 50%;
}

.homebanner-p {
  background: white;
  height: calc(100vh - -2vh);

  position: relative;
  z-index: 0;
}

#home-page {
  background: white;
  height: calc(100vh - 20vh);

  position: relative;
  z-index: 0;
}

.pink-bg {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: -50px;
  justify-content: center;

}

.modal-dialog {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.homebanner-p::before {
  content: "";
  position: absolute;
  top: -330px;
  left: -230px;
  width: 600px;
  height: 600px;
  background-color: #e31e25;
  border-radius: 50%;
  z-index: 2;
}

.homebanner-child-text {
  position: relative;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.homebanner-child-text h1 {
  font-size: 26px;
  color: black;
  font-weight: 700;
  margin-bottom: 12px;
}

.homebanner-child-text h1 span {
  font-size: 40px;
  font-weight: 700;
}

.homebanner-child-text h4 {
  font-size: 24px;
  color: black;
  margin-bottom: 0;
  font-weight: 500
}

.homebanner-child-text p {
  font-size: 20px;
  color: black;
  margin-top: 58px;
  margin-bottom: 58px;
  max-width: 1041px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
}

.homebanner-child-text button {
  background: #050708;
  color: white;
  height: 50px;
  width: 240px;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  border: 0;
  font-size: 18px;
}

.homebanner-child-text button:hover {
  background: #e31e25;
  color: white;
  text-decoration: none;
}

/* modal */
.modal-banner-wrapper {
  z-index: 999999;
}

.modal-banner-wrapper .modal-header {
  justify-content: flex-end;
  background: #d9d9d9;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.modal-banner-wrapper .modal-header img {
  cursor: pointer;
}

.modal-banner-wrapper .modal-content {
  background: none;
}

.modal-banner-wrapper .modal-body {
  background: white;
  padding: 20px 10%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.modaldollor {
  text-align: center;

  margin-bottom: 20px;
}

.modaldollor h4 {
  font-weight: 700;
  font-size: 17px;

  color: #000000;
  margin-bottom: 0;
}

.modaldollor h3 {
  font-weight: 700;
  font-size: 25px;

  color: #000000;
  margin-bottom: 20px;
}

.modal-banner-wrapper input,
.modal-banner-wrapper select {
  width: 100%;
  border: none;
  background: #ededed;
  margin-bottom: 10px;
  font-size: 11.075px;
  height: 40px;
  border-radius: 6px;
  padding-left: 10px;
}

.modal-banner-wrapper input::placeholder {
  padding-left: 10px;
}

.phone-no-modal {
  display: flex;
  gap: 10px;
}

.phone-no-modal select {
  width: 18%;
  background-color: white;
}

.phone-no-modal select::placeholder {
  padding-left: 10px;
}

.modal-banner-wrapper textarea {
  width: 100%;
  border: none;
  background: #ededed;
  font-size: 11.075px;
  height: 70px;
  border-radius: 6px;
  padding: 10px;
}

.modal-banner-wrapper textarea::placeholder {
  padding: 10px;

}

.btn-mdl-red {
  background: #e31e25;
  color: white;
  padding: 8px 30px;
  border: none;
  border-radius: 8px;
}

.btn-mdl-wrapper {
  text-align: center;
  margin-top: 20px;
}

.PhoneInput {
  width: 100%;
}

.css-b62m3t-container {
  padding-bottom: 3%;
  width: 35%;
  height: 40px;
}

.css-t3ipsp-control {
  height: 40px !important;
  border-width: 0px !important;
  background: #ededed !important;
  border-radius: 6px !important;
  width: 92%;
}

.css-t3ipsp-control .css-1jqq78o-placeholder {
  padding: 6px;
}

.css-t3ipsp-control .css-1cfo1cf {
  padding-bottom: 10px !important;
  padding-top: 0px !important;
  margin: 0px !important;
}

.css-13cymwt-control {
  height: 40px !important;
  border-width: 0px !important;
  background: #ededed !important;
  border-radius: 6px !important;
  width: 92%;
}

.css-13cymwt-control .css-1jqq78o-placeholder {
  display: flex;
  padding-left: 6px;
  padding-bottom: 2px;
  align-items: baseline;
}

.css-qbdosj-Input:after {
  min-width: 5px !important;
}

.css-1fdsijx-ValueContainer {
  background: #ededed;
  border-radius: 6px !important;
  height: 40px;

}

.css-1hb7zxy-IndicatorsContainer {
  background: #ededed;
  border-radius: 6px !important;
  height: 40px;
}

.css-qbdosj-Input {
  font-size: 11.075px;
  padding: 0px 15px !important;
  height: 40px;
  margin-bottom: -2px !important;
  margin: 0 !important;
}

.css-19bb58m {
  margin: 0 !important;
  padding-top: 0 !important;
}

.css-hlgwow {
  height: 40px !important;
}

.css-166bipr-Input {
  display: flex !important;
  align-self: start !important;
  margin: 0 !important;
  padding-top: 0 !important;
}

.css-1nmdiq5-menu {
  width: 150% !important;
}

.award {
  background: linear-gradient(180deg, #2D0406 26.04%, #070000 100%);
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.context {
  display: flex;
  justify-content: space-between;
}

.context-left {
  padding-top: 67px;
  padding-left: 85px;
  height: 370px;
}

.context-left p {
  width: 674px;
  color: #FFF;
  font-family: 'Inter';
  font-size: 29.626px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 69px;
}

.context-right {
  padding-right: 15%;
  position: relative;
  padding-top: 67px;
}

.effectleft {
  position: absolute;
  right: 57%;
  top: 40%;
  mix-blend-mode: screen;
  width: 450px;
}

.effectright {
  position: absolute;
  right: 4%;
  mix-blend-mode: screen;
  top: 46%;
  width: 450px;
}

.logos {
  display: flex;
  padding-left: 85px;
  margin-top: -5%;
  gap: 5%;
  padding-bottom: 2%;
}

.logos-right {
  display: flex;
}

.logos p {
  color: #FFF;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logos a {
  color: #FFF;
  text-align: center;
  font-family: 'Inter';
  font-size: 15.617px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.logos-right-left,
.logos-right-right {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

@media (max-width: 1680px) {
  .effectleft {
    right: 55%;
    top: 47%;
    width: 350px;
  }

  .effectright {
    position: absolute;
    right: 12%;
    mix-blend-mode: screen;
    top: 52%;
    width: 350px;
  }
}

@media (max-width:1440px) {
  #home-page {
    height: 110vh;
  }
}

@media (max-width:1366px) {
  .modal-dialog {
    display: flex;
    justify-content: center;
  }

  .effectleft {
    position: absolute;
    right: 53%;
    top: 48%;
    mix-blend-mode: screen;
  }

  .effectright {
    position: absolute;
    right: 7%;
    mix-blend-mode: screen;
    top: 53%;
  }
}

@media (max-width:920px) {
  #home-page {
    height: 700px;
  }

  .css-b62m3t-container {
    width: 50%;
  }

  .context {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .context-left img {
    width: 137.162px;
    height: 45.127px;
  }

  .context-left p {
    font-size: 16.712px;
    width: 348px;
    text-align: center;
    padding-top: 16px;
  }

  .context-left {
    padding-top: 22px;
    height: 370px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 155px;
  }

  .context-right {
    padding-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    padding-bottom: 40px;
  }

  .logos {
    display: flex;
    gap: 11%;
    padding-bottom: 5%;
    padding-left: 38px;
    margin-top: 0;
  }

  .logos p {
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 11.282px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    line-height: normal;
  }

  .logos a {
    color: #FFF;
    text-align: center;
    font-family: "Inter";
    font-size: 8.809px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .logos-right img {
    width: 73.142px;
    height: 29.158px;
  }

  .logos-left img {
    width: 106.048px;
    height: 25.919px;
  }

  .logos-right {
    margin-top: -4px;
  }

  .effectleft {
    right: 42%;
    top: 45%;
  }

  .effectright {
    right: 31%;
    top: 50%;
  }
}

@media (max-width: 650px) {
  .homebanner-p::before {
    top: -396px;
    left: -306px;
  }
  .award{
    margin-bottom: 20%;
  }

  #home-page {
    height: 700px;
  }

  .homebanner-child-text h1 {
    font-size: 16px;
  }

  .homebanner-child-text h1 span {
    font-size: 27px;
  }

  .homebanner-child-text h4 {
    font-size: 13px;
  }

  .homebanner-child-text p {
    max-width: 90%;
    font-size: 15px;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .homebanner-child-text {
    top: 40rem;
  }

  .homebanner-child-text button {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.10)) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.10)) drop-shadow(0px 19px 19px rgba(0, 0, 0, 0.09)) drop-shadow(0px 43px 26px rgba(0, 0, 0, 0.05)) drop-shadow(0px 76px 30px rgba(0, 0, 0, 0.01)) drop-shadow(0px 118px 33px rgba(0, 0, 0, 0.00));
  }

  .pink-bg {
    margin-bottom: 1.5rem;
    top: 0;
  }


}

@media (max-width:640px) {
  #home-page {
    height: 700px;
  }

  .effectleft {
    right: 35%;
    top: 41%;
  }

  .effectright {
    right: 2%;
    mix-blend-mode: screen;
    top: 46%;
  }

  .logos {
    gap: 50%;
  }
}

@media (max-width: 430px) {
  #home-page {
    height: 700px;
  }

  .pink-bg {
    margin-top: 2rem;
    top: 0;
  }

  .logos {
    gap: 25%;
  }

  .effectleft {
    right: 31%;
    top: 47%;
    width: 285px;
  }

  .effectright {
    right: 4%;
    mix-blend-mode: screen;
    top: 51%;
    width: 255px;
  }
}

@media (max-width: 375px) {
  .pink-bg {
    margin-top: 2rem;
    top: 0;
  }

  #home-page {
    height: 700px;
  }

  .logos {
    gap: 10%;
  }

  .effectleft {
    right: 28%;
    top: 43%;
    width: 285px;
  }

  .effectright {
    right: 1%;
    top: 50%;
    width: 245px;
  }
}

@media (max-width: 360px) {
  #home-page {
    height: 700px;
  }
}

@media (max-width: 300px) {
  #home-page {
    height: 115vh;
  }
}

.pink-bg .containerr {
  width: 100%;
  overflow-x: hidden;
  padding-top: 25px;
  margin-right: 2px;
}

.roww {
  display: inline-flex;
  margin-top: -32px;
  margin-left: -50px;
}

.roww:nth-child(even) {
  margin-left: 2px;
}

.hexagon {
  background: rgba(0, 0, 0, 0.05);
  width: 100px;
  height: 110px;
  margin: 2px;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  animation: animation 3s linear infinite;
  transition: 1s;
}

.hexagon:hover {
  background: red;
  transition: 0s;
}

@keyframes animation {
  0% {
    filter: grayscale(20);
  }

  100% {
    filter: grayscale(0);
  }
}

.hexagon::after {
  content: '';
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  background-color: whitesmoke;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.hexagon:before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: rgba(114, 114, 114, 0.03);
  z-index: 2;
}


.submit-modal-content {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}


.error {
  color: red;
}

.invalid-input .modal-banner-wrapper input {
  border: 1px solid red;
}

#demo-modal .modal-content {
  width: 82%;
}