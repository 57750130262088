.UseAi-slider .slick-prev:before,
.UseAi-slider .slick-next:before {
    display: none;
}

.UseAi-slider .slick-prev,
.UseAi-slider .slick-next {
    background: none !important;
    display: none !important;
    padding: 0 !important;
    top: 50%;
}

.UseAi-slider .slick-prev {
    left: 4%;
    z-index: 1;
}

.UseAi-slider .slick-next {
    right: 5%;
}

.UseAi-slider .slick-dots {
    margin-top: 44px;
    font-size: 50px;
}

.UseAi-slider .slick-dots li button:before {
    font-size: 10px;
    top: 44px;
}

.UseAi-slider .slick-dots li.slick-active:nth-child(1) button::before {
    color: #303483;
    opacity: 1;
    font-size: 16px;
}

.UseAi-slider .slick-dots li.slick-active:nth-child(2) button::before {
    color: #CBE1C7;
    opacity: 1;
    font-size: 16px;
}

.UseAi-slider .slick-dots li.slick-active:nth-child(3) button::before {
    color: #F5D291;
    opacity: 1;
    font-size: 16px;
}

.UseAi-slider .slick-dots li.slick-active:nth-child(4) button::before {
    color: #DFEFFA;
    opacity: 1;
    font-size: 16px;
}

.UseAi-slider .slick-dots li.slick-active:nth-child(5) button::before {
    color: #F0B9AC;
    opacity: 1;
    font-size: 16px;
}

.finace-p-grid {
    /* display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px,1fr)); */
    display: flex;
    gap: 14px;
    max-width: 75%;
    margin: auto;
    /* padding-top: 5%; */

}

.finace-p-top-h {
    padding-top: 0 !important;

}

.finance-c-d {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    width: 25%;
}

.finance-c-d:before {
    background: transparent !important;
}

.finance-c-d:hover h3 {

    font-weight: 700;
}

.finance-c-d h3 {
    padding-top: 5rem;
    padding-bottom: 3rem;
    font-size: 1.6rem;
    text-align: center;
    transition: all 0.3s ease-in-out
}

.finance-c-d img {
    border-radius: 8px;

}

@media (max-width:650px) {
    .finance-c-d {
        width: 252px;
        height: 366px;
    }

    .finance-c-d:hover {
        width: 252px;
        height: 366px;
    }

    .finance-c-d h3 {
        font-weight: 700;
    }

    .finace-p-grid {
        flex-wrap: wrap;
    }

    .slick-initialized .slick-slide {
        display: flex;
        justify-content: center;
    }

    .UseAi-slider {
        margin-bottom: 25%;
    }
}