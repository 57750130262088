.demo-main::before {
    content: '';
    background-image: url(./bg.png);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.inner-section {
    border-radius: 15px !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%) !important;
    backdrop-filter: blur(62.2662239074707px) !important;
    width: 70%;
    margin: auto;
}

.sub-section {
    border-radius: 15px 15px 15px 0px;
    background: rgba(255, 255, 255, 0.10) !important;
}

.demo-button {
    border-radius: 8px;
    background: #E31E25 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    text-align: center;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 87.5% */
    text-transform: uppercase;
}

@media (max-width: 600px) {
    .demo-main {
        padding-top: 30% !important;
        max-width: 100% !important;
    }

    .inner-section {
        width: 100%;

    }
}