.footer-main {
  display: flex;
  gap: 50px;
  max-width: 90%;
  margin: auto;
  padding-top: 6rem;
}

.seperator:hover {
  color: white;
}

.footer-top-d {
  background: #151515;
}

.footc p,
.footc a {
  font-size: 1.8rem;
  color: white;
  font-weight: 300;
}

.footc p:hover,
.footc a:hover {
  text-decoration: none !important;
  color: #E31E25;
}

.footc h4 {
  color: white;
  margin-bottom: 4rem;
  font-size: 1.8rem;
  font-weight: 600;
}

.foot2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.foot3 {
  text-align: center;
}

.foot2 a {
  margin-top: 10px;
}

.footc {
  width: 33.3%;
}

.social-icons-footer {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.footer-bot-text {
  text-align: center;
}

.footer-bot-text p {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.footerlogo-flex {
  max-width: 90%;
  margin: auto;
  display: flex;
  margin-top: 3%;
  border-bottom: 1px solid white;
  padding-bottom: 20px;
}

.footerlogo-left {
  width: 40%;
}

.footerlogo-right {
  width: 60%;
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
}

.footerlogo-right a:hover,
.footer-copyright a:hover {
  text-decoration: none !important;
  color: #E31E25;
}

.footer-copyright p:hover {
  color: #E31E25;
}

.footerlogo-right h4,
.footerlogo-right a {
  color: white;
}

.footerlogo-right a {
  font-size: 1.2rem;
}

.footerlogo-right-c {
  max-width: 28%;
}

.socail-icons a {
  margin-right: 10px;
}

.b-f-r-l {
  border-left: 1px solid white;
  border-right: 1px solid white;
  padding: 0px 12px;
}

.socail-icons img:hover {
  filter: invert(100%);
}

.footer-copyright {
  max-width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 0;
}

.footer-copyright p {
  margin: 0;
  color: white;
  font-size: 1.2rem;
}

.foot1a {
  display: none;
}

@media (max-width: 915px) {
  .footc {
    width: 100%;
  }

  .footer-main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2px;
  }

  .footerlogo-flex {
    flex-wrap: wrap;
    gap: 20px;
  }

  .footerlogo-left {
    width: 100%;
    text-align: center;
  }

  .footerlogo-right {
    width: 100%;
    flex-wrap: wrap;
  }

  .footer-copyright {
    gap: 5px;
    flex-direction: column;
  }

  .footerlogo-right a {
    font-size: 1rem;
  }

  .logos-bottom {
    display: flex;
    scale: 0.9;
    align-items: center;
    gap: 120px;
  }

  .social-iconsmobile {
    display: flex;
    gap: 4px;
  }

  .foot1a {
    display: block;
  }

  .foot1 {
    display: none;
  }

  .expanded {
    display: block !important;
  }

  .foot1a h4 {
    cursor: pointer;
  }

  .foot1a .arrow {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.2s;
  }

  .foot1a.expanded .arrow.up {
    transform: rotate(180deg);
  }

  .footerlogo-right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .b-f-r-l {
    border: none;
    padding: 0px;
  }

  .footer-heading {
    display: flex;
    color: white;
    align-items: baseline;
    justify-content: space-between;

  }
  .footc p, .footc a{
    font-size: 15px;
  }
  .foot1a h4{
    font-size: 16px;
  }
}


.pickdemo p:hover {
  color: #E31E25;
  cursor: pointer;
}