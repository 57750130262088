.feature-test-btmbr {
    width: 100%;
    margin: 85px 0px;
}

.feature-short-btmbr {
    display: none;
}


.f-use-cases-wrapper {
    margin-top: 50px;
    padding-bottom: 15%;
}

.h-poi-b-line {
    position: relative;
    margin-bottom: 8%;
    max-width: 860px;
}

.h-poi-b-line::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 15%;
    background: black;
    bottom: -33px;
    left: 50%;
    transform: translateX(-50%);

}

.f-use-cases-flex {
    max-width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;


}

.f-use-cases-inner {
    filter: drop-shadow(0px 19px 8px rgba(0, 0, 0, 0.02)) drop-shadow(0px 11px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.12));
    background: #FFFFFF;
    border-radius: 12px;
    width: 25%;
    padding-top: 3%;
    padding-bottom: 2%;

    display: flex;
    flex-direction: column;
    align-items: center;

}

.f-use-cases-inner p {
    text-align: center;

    color: #242424;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 20px;
    min-height: 45px;

}

.feature-m-s-wrapper {
    background: #ECECEC;
    padding: 5% 0%;
}

.features-inbound-outbound-wrapper {
    padding-top: 0;
}

.feature-m-s-flex {
    display: flex;
    gap: 30px;
    max-width: 70%;
    margin: auto;
    margin-top: 5%;
    justify-content: center;
}

.feature-m-s-flex>div {
    width: 411px;
}

.feature-m-s-flex-like {
    background: linear-gradient(171.39deg, #3F3F3F 3.19%, #525252 100%);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 70%;
    padding: 10px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.feature-m-s-flex-like p {
    font-size: 1.8rem;
    color: white;
    margin-bottom: 0;
}

.feature-m-s-flex-dislike {
    background: linear-gradient(171.39deg, #CB2E3A 3.19%, #F23E45 100%);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 70%;
    padding: 10px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.feature-m-s-flex-dislike p {
    font-size: 1.8rem;
    color: white;
    margin-bottom: 0;
}

.feature-m-s-flex-left-tic-wrapper {
    background: linear-gradient(179.94deg, #FFFFFF 0.06%, #ECECEC 104.63%);
    border-radius: 14px;
    padding: 36px 19px;
}

.feature-m-s-flex-left-tic-flex {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 17px;
}

.feature-m-s-flex-left-tic-flex p {
    margin-bottom: 0;
    font-size: 16px;
}

.use-cases-inner-div-f-flex {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
}

.use-cases-inner-div-f-flex>div {
    width: 224px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 33px;
}

.inner-use-cases-d {
    border-radius: 14px;
    /* padding: 30px; */
    height: 340px;
}

.case1 {
    background: #BDDAF3;
}

.case2 {
    background: #D0E5DB;
}

.case3 {
    background: #FEEDE0;
}

.img-use-cases-f-inner {
    background: white;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-use-cases-d p {

    margin-bottom: 0;
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
}

.inner-use-cases-d p span {

    font-weight: 600;
}

.use-cases-features-wrapper {
    margin-bottom: 12%;
}

.bg-supported-language {
    background: url('../../images//Features/bg-language.png');
    padding-top: 4%;
    padding-bottom: 8%;
}

.flex-languages {
    max-width: 58%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 39px;
    flex-wrap: wrap;
    justify-content: center;
}

.flex-lang-inner {
    width: 18%;
    display: flex;
    gap: 16px;
    align-items: center;
}

.flex-lang-inner p {
    margin-bottom: 0;

    font-size: 18px;
}

@media (max-width:600px) {
    #featurestop {
        height: calc(100vh - -21vh)
    }

    .f-use-cases-inner {
        width: 40%;

    }

    .f-use-cases-inner p {
        font-size: 13px;
        min-height: 25px;
    }

    .feature-test-btmbr {
        display: none;
    }

    .feature-short-btmbr {
        margin: 85px 0px;
    }

    .f-use-cases-flex {
        max-width: 90%;
        margin: 8rem auto 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;


    }

    .feature-m-s-flex>div {
        width: 100%;
    }

    .feature-m-s-flex {
        flex-wrap: wrap;
        max-width: 90%;
    }

    .use-cases-inner-div-f-flex {
        flex-wrap: wrap;
        margin-top: 6rem;
    }

    .use-cases-inner-div-f-flex>div {
        width: 100%;
    }

    .flex-languages {
        max-width: 90%;
        gap: 0;
        row-gap: 30px;
        margin-left: 45px;

    }

    .flex-lang-inner {
        width: 50%;
    }

    .flex-lang-inner {
        justify-content: start
    }

    .feature-m-s-flex-like p {
        font-size: 1.4rem;

    }

    .feature-m-s-flex-like {
        width: 82%;
    }

    .feature-m-s-flex-dislike {
        width: 82%;
    }

    .feature-m-s-flex-dislike p {
        font-size: 1.4rem;

    }

    .feature-m-s-flex-left-tic-flex p {

        font-size: 1.4rem;
    }
}
